import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link, FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"

import FadeIn from "react-fade-in"

import Language from "../language"
import BurgerClose from "./burgerClose"
import SidebarOverlay from "./sidebarOverlay"

const Header = ({ siteTitle, intl }) => {
  const data = useStaticQuery(graphql`
    query {
      krtfa: file(relativePath: { eq: "krtfa-logo-transparent.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      taobao: file(relativePath: { eq: "taobao-logo-transparent.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)
  const [open, setOpen] = useState(false)
  return (
    <>
      <header>
        <SidebarOverlay open={open} setOpen={setOpen} />
        <Container fluid>
          <FadeIn>
            <Navbar collapseOnSelect expand="xl" variant="dark">
              <Link to="/">
                <Navbar.Brand>
                  <Img
                    fixed={data.krtfa.childImageSharp.fixed}
                    className="d-inline-block align-top"
                    alt="KRTFA Logo"
                  />
                  <Img
                    fixed={data.taobao.childImageSharp.fixed}
                    className="d-inline-block align-top"
                    alt="KRTFA Logo"
                  />
                </Navbar.Brand>
              </Link>
              <Nav className="ml-auto d-xl-none">
                <BurgerClose open={open} setOpen={setOpen} />
              </Nav>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto justify-content-end">
                  <Link
                    to="/"
                    className="nav-link"
                    activeClassName="active-link"
                  >
                    <FormattedMessage
                      id="header.navlink.home"
                      defaultMessage="Introduction"
                    />
                  </Link>
                  <NavDropdown
                    title={
                      <FormattedMessage
                        id="header.navlink.whatwedo"
                        defaultMessage="What We Do"
                      />
                    }
                    className="caret"
                  >
                    <Link to="/about" className="dropdown-item" role="button">
                      <FormattedMessage
                        id="header.navlink.about"
                        defaultMessage="About Us"
                      />
                    </Link>
                    <Link to="/taobao" className="dropdown-item" role="button">
                      <FormattedMessage
                        id="header.navlink.taobao"
                        defaultMessage="Taobao Global"
                      />
                    </Link>
                    <Link
                      to="/taobaosolution"
                      className="dropdown-item"
                      role="button"
                    >
                      <FormattedMessage
                        id="header.navlink.taobaosolution"
                        defaultMessage="Taobao Solution"
                      />
                    </Link>
                    <Link
                      to="/organization"
                      className="dropdown-item"
                      role="button"
                    >
                      <FormattedMessage
                        id="header.navlink.organization"
                        defaultMessage="Organization Chart"
                      />
                    </Link>
                    <Link to="/history" className="dropdown-item" role="button">
                      <FormattedMessage
                        id="header.navlink.history"
                        defaultMessage="History"
                      />
                    </Link>
                    <Link to="/events" className="dropdown-item" role="button">
                      <FormattedMessage
                        id="header.navlink.events"
                        defaultMessage="Events"
                      />
                    </Link>
                  </NavDropdown>
                  <Link
                    to="/contact"
                    className="nav-link"
                    activeClassName="active-link"
                  >
                    <FormattedMessage
                      id="header.navlink.contact"
                      defaultMessage="Contact Us"
                    />
                  </Link>
                  <Link
                    to="/media"
                    className="nav-link"
                    activeClassName="active-link"
                  >
                    <FormattedMessage
                      id="header.navlink.media"
                      defaultMessage="Media"
                    />
                  </Link>
                  <div className="vl" />
                  <Language />
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </FadeIn>
        </Container>
      </header>
      <hr className="header-hr" />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default injectIntl(Header)
