/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import {
  useIntl,
  IntlContextConsumer,
  changeLocale,
  FormattedMessage,
  injectIntl,
} from "gatsby-plugin-intl"

import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"

const languageName = {
  en: "EN",
  ko: "KO",
  cn: "CN",
}

const Language = () => {
  const intl = useIntl()
  return (
    <>
      <NavDropdown
        className="text-uppercase caret sidebar-mobile-dropdown"
        title={
          <FormattedMessage
            id={`header.language.${languageName[intl.locale]}`}
            defaultMessage={languageName[intl.locale]}
          />
        }
      >
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            // eslint-disable-next-line array-callback-return
            languages.map(language => {
              if (currentLocale !== language)
                return (
                  <a
                    key={language}
                    onClick={() => changeLocale(language)}
                    className="dropdown-item language"
                  >
                    <FormattedMessage
                      id={`header.language.${languageName[language]}`}
                      defaultMessage={languageName[language]}
                    />
                  </a>
                )
            })
          }
        </IntlContextConsumer>
      </NavDropdown>
      <Nav className="d-flex sidebar-mobile-nav">
        <a href="/" className="nav-link language active-link">
          <FormattedMessage
            id={`header.language.${languageName[intl.locale]}`}
            defaultMessage={languageName[intl.locale]}
          />
        </a>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            // eslint-disable-next-line array-callback-return
            languages.map(language => {
              if (currentLocale !== language)
                return (
                  <a
                    key={language}
                    onClick={() => changeLocale(language)}
                    className="nav-link language"
                  >
                    <FormattedMessage
                      id={`header.language.${languageName[language]}`}
                      defaultMessage={languageName[language]}
                    />
                  </a>
                )
            })
          }
        </IntlContextConsumer>
      </Nav>
    </>
  )
}

export default injectIntl(Language)
