import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link, FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"

import FadeIn from "react-fade-in"

const Footer = ({ siteTitle, intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiKoPageInfo {
        edges {
          node {
            strapiId
            shortMessage
            longMessage
            navigation {
              id
              url
              title
            }
          }
        }
      }
      allStrapiEnPageInfo {
        edges {
          node {
            strapiId
            shortMessage
            longMessage
            navigation {
              id
              url
              title
            }
          }
        }
      }
      allStrapiCnPageInfo {
        edges {
          node {
            strapiId
            shortMessage
            longMessage
            navigation {
              id
              url
              title
            }
          }
        }
      }
      krtfa: file(relativePath: { eq: "krtfa-logo-transparent.png" }) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      taobao: file(relativePath: { eq: "taobao-logo-transparent.png" }) {
        childImageSharp {
          fixed(height: 55) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  let strapiNode
  switch (intl.locale) {
    case "en":
      strapiNode = data.allStrapiEnPageInfo.edges[0].node
      break
    case "ko":
      strapiNode = data.allStrapiKoPageInfo.edges[0].node
      break
    case "cn":
      strapiNode = data.allStrapiCnPageInfo.edges[0].node
      break
    default:
      break
  }

  let divider = false
  let navigationLinks = strapiNode.navigation.map(page => {
    if (divider) {
      return (
        <React.Fragment key={page.id}>
          <div className="vl" />
          <Nav.Item>
            <Link
              to={page.url}
              className="nav-link"
              activeClassName="active-link"
            >
              <p className="mb-0">{page.title}</p>
            </Link>
          </Nav.Item>
        </React.Fragment>
      )
    } else {
      divider = true
      return (
        <React.Fragment key={page.id}>
          <Nav.Item>
            <Link
              to={page.url}
              className="nav-link"
              activeClassName="active-link"
            >
              <p className="mb-0">{page.title}</p>
            </Link>
          </Nav.Item>
        </React.Fragment>
      )
    }
  })

  if (!navigationLinks.length) {
    navigationLinks = <></>
  }

  const footer = {
    shortMessage: strapiNode.shortMessage,
    longMessage: strapiNode.longMessage,
  }

  return (
    <>
      <hr className="footer-hr" />
      <footer>
        <Container fluid>
          <FadeIn>
            <Navbar variant="dark">
              <Col xs={12} sm={4} className="d-flex footer-disappear-col">
                <Link to="/">
                  <Navbar.Brand>
                    <Img
                      fixed={data.krtfa.childImageSharp.fixed}
                      className="d-inline-block align-top"
                      alt="KRTFA Logo"
                    />
                  </Navbar.Brand>
                </Link>
                <div className="vl footer-disappear" />
                <Link to="/">
                  <Navbar.Brand className="footer-disappear">
                    <Img
                      fixed={data.taobao.childImageSharp.fixed}
                      className="d-inline-block align-top "
                      alt="KRTFA Logo"
                    />
                  </Navbar.Brand>
                </Link>
              </Col>
              <Nav
                className="align-items-center mr-auto d-none d-lg-flex"
                activeKey="/home"
              >
                {navigationLinks}
              </Nav>
            </Navbar>
            <Row className="p-3">
              <Col xs={12} md={4}>
                <p>{footer.shortMessage}</p>
              </Col>
              <Col md={8}>
                <p className="pl-1">{footer.longMessage}</p>
              </Col>
            </Row>
          </FadeIn>
        </Container>
      </footer>
    </>
  )
}

export default injectIntl(Footer)
