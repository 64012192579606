/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react"
import { bool } from "prop-types"
import { Link, FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Nav from "react-bootstrap/Nav"

import BurgerOpen from "./burgerOpen"
import Language from "../language"

const SidebarOverlay = ({ intl, open, setOpen }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "scroll"
      document.body.style.position = "fixed"
    } else {
      document.body.style.overflowY = "unset"
      document.body.style.position = "relative"
    }
  }, [open])

  return (
    <div
      className="sidebar-menu"
      open={open}
      style={{ transform: open ? "translateX(-100%)" : "translateX(0)" }}
    >
      <BurgerOpen open={open} setOpen={setOpen} />
      <Nav className="ml-auto mr-auto flex-column">
        <Link to="/" className="nav-link" activeClassName="active-link">
          <FormattedMessage
            id="header.navlink.home"
            defaultMessage="Introduction"
          />
        </Link>
        <Link to="/about" className="nav-link " activeClassName="active-link">
          <FormattedMessage
            id="header.navlink.about"
            defaultMessage="About Us"
          />
        </Link>
        <Link to="/taobao" className="nav-link " activeClassName="active-link">
          <FormattedMessage
            id="header.navlink.taobao"
            defaultMessage="Taobao Global"
          />
        </Link>
        <Link to="/taobaosolution" className="nav-link " activeClassName="active-link">
          <FormattedMessage
            id="header.navlink.taobaosolution"
            defaultMessage="Taobao Solution"
          />
        </Link>
        <Link to="/organization" className="nav-link " activeClassName="active-link">
          <FormattedMessage
            id="header.navlink.organization"
            defaultMessage="Organization Chart"
          />
        </Link>
        <Link
          to="/history"
          className="nav-link sidebar-dropdown-item"
          activeClassName="active-link"
        >
          <FormattedMessage
            id="header.navlink.history"
            defaultMessage="History"
          />
        </Link>
        <Link
          to="/events"
          className="nav-link sidebar-dropdown-item"
          activeClassName="active-link"
        >
          <FormattedMessage
            id="header.navlink.events"
            defaultMessage="Events"
          />
        </Link>
        <Link to="/contact" className="nav-link" activeClassName="active-link">
          <FormattedMessage
            id="header.navlink.contact"
            defaultMessage="Contact Us"
          />
        </Link>
        <Link to="/media" className="nav-link" activeClassName="active-link">
          <FormattedMessage id="header.navlink.media" defaultMessage="Media" />
        </Link>
        <Language />
      </Nav>
    </div>
  )
}

SidebarOverlay.propTypes = {
  open: bool.isRequired,
}

export default injectIntl(SidebarOverlay)
