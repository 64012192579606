/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { bool, func } from "prop-types"

const BurgerOpen = ({ open, setOpen }) => (
  <div
    className="burger-menu burger-menu-open ml-auto"
    open={open}
    onClick={() => setOpen(!open)}
  >
    <div
      className="bar"
      style={{
        transform: open ? "rotate(45deg)" : "rotate(0)",
        background: open ? "#fff" : "#000",
      }}
    />
    <div
      className="bar"
      style={{
        opacity: open ? "0" : "1",
        transform: open ? "translateX(20px)" : "translateX(0)",
      }}
    />
    <div
      className="bar"
      style={{
        transform: open ? "rotate(-45deg)" : "rotate(0)",
        background: open ? "#fff" : "#000",
      }}
    />
  </div>
)

BurgerOpen.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
}

export default BurgerOpen
